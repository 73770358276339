import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.css'
import Modal from 'components/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import withToggle, { toggleShape } from 'containers/Toggle'
const PlotHelp = ({ className, plotType, toggledOn, toggle }) => {
  return (
    <>
      <FontAwesomeIcon icon="question-circle" onClick={toggle}/>

      <Modal
        className={cx(className, styles.PlotHelp)}
        visible={toggledOn}
        hideModal={toggle}
        closeTimeoutMS={1150}
        title={plotType.title}

      >
        <article>
          {plotType.help || 'oOPS'}
        </article>
      </Modal>
    </>
  )
}

PlotHelp.propTypes = {
  className: PropTypes.string,
  plotType: PropTypes.shape({
    title: PropTypes.string.isRequired,
    help: PropTypes.string.isRequired,
  }),
  toggle: PropTypes.func.isRequired,
  toggledOn: PropTypes.bool,
}

export default withToggle(PlotHelp)
